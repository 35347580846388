import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  .copyrights {
    background: rgb(13, 12, 12);
    text-align: center;
    padding: 10px;
  }
  .copyrights p,
  .copyrights .credits {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    color: #fff;
  }
  .copyrights p a,
  .copyrights .credits a {
    letter-spacing: 0;
  }
`;

export default function UnderFooter() {
  return (
    <Wrapper>
      <div className="copyrights">
        <div className="container">
          <p>
            &copy; Copyrights <strong>BrainXone</strong> -{" "}
            {new Date().getFullYear()}. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </Wrapper>
  );
}
