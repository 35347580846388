import React from "react";
import styled from "styled-components";
import { LightLink, RoundLightLink } from "./StyledComponents";

const Wrapper = styled.section`
  padding: ${(props) => props.theme.paddingSize * 2}px;
  color: ${(props) => props.theme.lightColor};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    padding: 20px 30px;
  }
`;

const Logo = styled.h1`
  font-size: 2em;
  font-weight: 900;
  display: inline-block;
  margin: 0;
`;

const HeaderLinks = styled.a`
  display: flex;
  gap: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export function Navbar() {
  return (
    <Wrapper>
      <Logo>BrainXone</Logo>
      <HeaderLinks>
        {/* <LightLink href="#contact">Qué hacemos</LightLink> */}
        {/* <LightLink href="#products">Productos</LightLink> */}
        {/* <LightLink href="#contact">Quienes somos</LightLink> */}
        {/* <LightLink href="#contact">Contactános</LightLink> */}
      </HeaderLinks>

      <HeaderLinks>
        <RoundLightLink href="#contact">Contactános</RoundLightLink>
      </HeaderLinks>
    </Wrapper>
  );
}
