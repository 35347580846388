import { darken, lighten } from "polished";
import React from "react";
import { ThemeProvider } from "styled-components";
import { Contact } from "./components/Contact";

import { Cover } from "./components/Cover";
import { Products } from "./components/Products";
import { Footer } from "./components/Footer";
import { Features } from "./components/Features";
import { Team } from "./components/Team";
import UnderFooter from "./components/UnderFooter";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivacyPolicy } from "./components/PrivacyPolicy";

const theme = {
  primaryColor: "#5e39d9",
  almostPrimaryColor: "#401fac",

  lightColor: "white",
  almostLightColor: darken(0.1, "white"),

  darkColor: "black",
  almostDarkColor: lighten(0.1, "black"),

  paddingSize: 30,
};

function Landing() {
  return (
    <>
      <Cover />
      <Products />
      {/* <Products /> */}
      {/* <Team /> */}
      <Contact />
      <Footer />
      <UnderFooter />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Landing />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
