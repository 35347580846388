import { lighten } from "polished";
import React from "react";
import styled from "styled-components";

export const Link = styled.a`
  text-decoration: none;
  font-weight: 500;
`;

export const LightLink = styled(Link)`
  color: ${(props) => props.theme.almostLightColor};
  &:hover {
    color: ${(props) => props.theme.lightColor};
  }
`;

export const DarkLink = styled(Link)`
  color: ${(props) => props.theme.almostDarkColor};
  &:hover {
    color: ${(props) => props.theme.darkColor};
  }
`;

export const RoundLink = styled(Link)`
  border: solid 1px;
  padding: ${(props) => props.theme.paddingSize / 2}px
    ${(props) => props.theme.paddingSize}px;
  border-radius: ${(props) => props.theme.paddingSize}px;
`;

export const RoundLightLink = styled(RoundLink)`
  color: ${(props) => props.theme.almostDarkColor};
  background: ${(props) => props.theme.almostLightColor};
  border-color: ${(props) => props.theme.almostLightColor};
  &:hover {
    color: ${(props) => props.theme.darkColor};
    background: ${(props) => props.theme.lightColor};
    border-color: ${(props) => props.theme.lightColor};
  }
`;

export const RoundOutlineLightLink = styled(RoundLink)`
  color: ${(props) => props.theme.almostLightColor};
  border-color: ${(props) => props.theme.almostLightColor};
  &:hover {
    color: ${(props) => props.theme.lightColor};
    border-color: ${(props) => props.theme.lightColor};
  }
`;

export const RoundFilledPrimaryLink = styled(RoundLink)`
  color: white;
  border-color: ${(props) => props.theme.almostPrimaryColor};
  background: ${(props) => props.theme.almostPrimaryColor};
  &:hover {
    background: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

const gap = "30px";

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: ${gap};
`;

export const Row = ({ children }) => {
  let virtualChildrenAmount = 0;

  React.Children.map(children, (child) => {
    virtualChildrenAmount += child.props.size || 1;
  });

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      widthPercent: (100 / virtualChildrenAmount) * (child.props.size || 1),
    });
  });

  return <RowWrapper>{childrenWithProps}</RowWrapper>;
};

export const RowItem = styled.div`
  width: calc(${({ widthPercent }) => widthPercent}% - ${gap});
  flex-grow: ${1};

  @media (max-width: 600px) {
    display: block;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  color: ${(props) => lighten(0.2, props.theme.primaryColor)};
  display: inline-block;
`;

export const Icon = ({ icon, size }) => {
  return (
    <IconWrapper>
      <i className={`fa-solid fa-${icon} fa-${size}x`}></i>
    </IconWrapper>
  );
};

export const BrandIcon = ({ icon }) => {
  return (
    <IconWrapper>
      <i className={`fa-brands fa-${icon}`}></i>
    </IconWrapper>
  );
};

export const Space = styled.div`
  margin: ${(props) => props.margin || "0"}px;
  padding: ${(props) => props.padding || "0"}px;

  @media (max-width: 600px) {
    margin: ${(props) => props.margin / 3 || "0"}px;
    padding: ${(props) => props.padding / 3 || "0"}px;
  }
`;

export const SpaceIndividual = styled.div`
  margin-top: ${(props) => props.marginTop || "0"}px;
  margin-left: ${(props) => props.marginLeft || "0"}px;
  margin-right: ${(props) => props.marginRight || "0"}px;
  margin-bottom: ${(props) => props.marginBottom || "0"}px;
  padding-top: ${(props) => props.paddingTop || "0"}px;
  padding-left: ${(props) => props.paddingLeft || "0"}px;
  padding-right: ${(props) => props.paddingRight || "0"}px;
  padding-bottom: ${(props) => props.paddingBottom || "0"}px;

  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: ${(props) => props.size || 2}em;
  padding-bottom: 0;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 1.6em;
  }
`;

export const SuperTitle = styled(Title)`
  font-size: 5em;

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

export const SubTitle = styled.p`
  font-size: ${(props) => props.size || 1.2}em;
  text-align: center;
  color: ${(props) => props.color || props.theme.darkColor};
`;
