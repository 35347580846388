import { lighten } from "polished";
import React, { useState } from "react";
import styled from "styled-components";
import { getConfig } from "../utils/configs";
import {
  RoundFilledPrimaryLink,
  Row,
  Space,
  SuperTitle,
} from "./StyledComponents";

const Wrapper = styled.div`
  text-align: center;
  background: ${(props) => lighten(0.42, props.theme.primaryColor)};

  .btn-contact {
    margin-top: 30px;
  }
`;

const FormWrapper = styled.div`
  width: 350px;
  margin: 0 auto;

  input,
  textarea {
    width: 90%;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    font-family: "Roboto", sans-serif;
  }
`;

export function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [confirmationContact, setConfirmationContact] = useState("");

  const sendContact = (e) => {
    e.preventDefault();

    // send data to api

    fetch(getConfig("CONTACT_API_URL"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        origin: "SONDEO_LANDING",
        name,
        email,
        message,
        subject,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setConfirmationContact(
            "¡Gracias por contactarnos! Nos comunicaremos con usted a la brevedad"
          );
        } else {
          setConfirmationContact("¡Ocurrió un error al enviar el mensaje!");
        }
      })
      .catch((error) => {
        setConfirmationContact("¡Ocurrió un error al enviar el mensaje!");
      });
  };

  return (
    <Wrapper id="contact">
      <Space padding={100}>
        <SuperTitle>Contactános</SuperTitle>
        <Space padding={30} />
        <Row>
          <FormWrapper>
            {confirmationContact && (
              <h4 className="contact-confirmation-message">
                {confirmationContact}
              </h4>
            )}
            <form className="email-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Nombre completo"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Correo electrónico"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Asunto"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows={5}
                  placeholder="Mensaje"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="btn-contact">
                <RoundFilledPrimaryLink onClick={sendContact} href="/">
                  Enviá mensaje
                </RoundFilledPrimaryLink>
              </div>
            </form>
          </FormWrapper>
        </Row>
      </Space>
    </Wrapper>
  );
}
