import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  BrandIcon,
  Link,
  Row,
  RowItem,
  Space,
  Title,
} from "./StyledComponents";

const InfoWrapper = styled.div`
  background: #171717;
  color: #c2c2c2;
  text-align: center;
`;

const LinksWrapper = styled.div`
  text-align: start;
  * {
    margin-top: 10px;
    color: white;
    text-decoration: none;
  }
`;

const BlockLink = styled(Link)`
  display: block;
`;

export function Footer() {
  return (
    <InfoWrapper>
      <Space padding={100}>
        {/* <SuperTitle>Recibí nuestras notificationes</SuperTitle>
        <Space padding={30} /> */}
        {/* <Row>
          <form action="">
            <input type="text" placeholder="Nombre" />
          </form>
        </Row> */}
        <LinksWrapper>
          <Row>
            <RowItem>
              <Title size={1.2}>Contactános</Title>
              <BlockLink>
                <BrandIcon icon="facebook" />{" "}
                <a
                  href="https://www.facebook.com/Brain-xone-100599952737763"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </BlockLink>
            </RowItem>

            <RowItem>
              <Title size={1.2}>Acerca nuestro</Title>
              <BlockLink>
                <NavLink to="privacy-policy">Política de privacidad</NavLink>
              </BlockLink>
            </RowItem>
            {/*
            <RowItem>
              <Title size={1.2}>Nuestros servicios</Title>
              <BlockLink>Hola</BlockLink>
              <BlockLink>Hola</BlockLink>
              <BlockLink>Hola</BlockLink>
              <BlockLink>Hola</BlockLink>
              <BlockLink>Hola</BlockLink>
              <BlockLink>Hola</BlockLink>
              <BlockLink>Hola</BlockLink>
            </RowItem> */}
          </Row>
        </LinksWrapper>
      </Space>
    </InfoWrapper>
  );
}
