import React from "react";
import styled from "styled-components";

const InfoWrapper = styled.div`
  margin: 30px;
`;

export function PrivacyPolicy() {
  return (
    <InfoWrapper>
      <h1 style={{ marginBottom: 70, marginTop: 40 }} className="page-title">
        Política de privacidad
      </h1>

      <p>
        La privacidad de sus datos, es una de nuestras principales prioridades.
        Solo accederemos a su cuenta para ayudarlo con un problema o solucionar
        un error de software. Nunca abriremos ningún archivo cargado a menos que
        nos lo solicite.
      </p>

      <h3>Identidad y acceso</h3>

      <p>
        Cuando se registra en Brainxone, le solicitamos los datos mínimos
        necesarios. Eso es solo para que pueda personalizar su nueva cuenta,
        actualizaciones u otra información esencial. Nunca venderemos su
        información personal a terceros, y tampoco usaremos su nombre o empresa
        en declaraciones de marketing sin su permiso.
      </p>

      <p>
        Cuando realiza el pago a Brainxone, lo hará a través de una de las
        opciones tercerizadas de pago, asegurando de esta manera sus datos de
        cuenta asi como las transacciones realizadas.
      </p>

      <p>
        Cuando escribe a Brainxone con una pregunta o para solicitar ayuda,
        conservaremos esa correspondencia y la dirección de correo electrónico
        para referencia futura. Cuando navegue por nuestras páginas de
        marketing, realizaremos un seguimiento con fines estadísticos (como
        tasas de conversión y para probar nuevos diseños). También almacenamos
        cualquier información que usted ofrezca voluntariamente, como encuestas,
        mientras tenga sentido.
      </p>

      <p>Las únicas veces que compartiremos su información:</p>

      <p>
        Para proporcionar productos o servicios que haya solicitado, con su
        permiso. Para investigar, prevenir o tomar medidas con respecto a
        actividades ilegales, sospechas de fraude, situaciones que impliquen
        amenazas potenciales a la seguridad física de cualquier persona,
        violaciones de nuestros Términos de servicio o según lo exija la ley. Si
        Brainxone es adquirido por otra empresa o se fusiona con ella, no
        planeamos eso, pero si sucede, le notificaremos mucho antes de que se
        transfiera cualquier información sobre usted y quede sujeta a una
        política de privacidad diferente.
      </p>

      <h3>Cumplimiento de la ley</h3>

      <p>
        Brainxone no entregará sus datos a las fuerzas del orden a menos que una
        orden judicial diga que tenemos que hacerlo. Rechazamos rotundamente las
        solicitudes de las fuerzas del orden público locales y federales cuando
        buscan datos sin una orden judicial. Y, a menos que nos lo impida
        legalmente, siempre le informaremos cuando se realicen dichas
        solicitudes.
      </p>

      <h3>Seguridad y cifrado</h3>

      <p>
        Todos los datos se cifran a través de SSL/TLS cuando se transmiten desde
        nuestros servidores a su navegador. Las copias de seguridad de la base
        de datos también están encriptadas. Los datos no se cifran mientras
        están activos en nuestra base de datos (ya que deben estar listos para
        enviárselos cuando los necesite).
      </p>

      <h3>Cambios y preguntas</h3>

      <p>
        Brainxone puede actualizar esta política de vez en cuando: le
        notificaremos sobre cambios significativos enviándole un correo
        electrónico al propietario de la cuenta o colocando un aviso destacado
        en nuestro sitio. Puede acceder, cambiar o eliminar su información
        personal en cualquier momento poniéndose en contacto con nuestro equipo
        de soporte.
      </p>

      <p>
        ¿Preguntas sobre esta política de privacidad? Póngase en contacto con{" "}
        <a href="mailto:contact@brainxone.com">nosotros</a> y estaremos
        encantados de responderle.
      </p>
    </InfoWrapper>
  );
}
