import { lighten } from "polished";
import React from "react";
import styled from "styled-components";
import { Row, RowItem, Space, SuperTitle, Title } from "./StyledComponents";

const Wrapper = styled.div`
  background: ${(props) => lighten(0.42, props.theme.primaryColor)};
`;

export function Features() {
  return (
    // <Row>
    //     <RowItem>
    //       <SpaceIndividual marginBottom={30}>
    //         <Icon icon="paper-plane" size={3} />
    //       </SpaceIndividual>
    //       <Title>Te implulsamos</Title>
    //       <span>
    //         Si tienes una idea, un proyecto o un negocio, te ayudamos a validar
    //         tus ideas con tus propios clientes antes de implementarlas.
    //         Ahorrando de esa manera tiempo y dinero en algo que tus clientes no
    //         necesitan. Dándoles la oportunidad de que sean parte de tu negocio y
    //         te ayuden a impulsarlo.
    //       </span>
    //     </RowItem>
    <Wrapper>
      <Space padding={100}>
        <SuperTitle>Hola dsf</SuperTitle>
        <Row>
          <RowItem size={2}>
            <Title>Track Your Expenses Very Quickly.</Title>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua ad
              minim veniam.
            </span>
          </RowItem>

          <RowItem>
            <Title>Analyse Your Expensees On Every Device.</Title>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua ad
              minim veniam.
            </span>
          </RowItem>

          <RowItem>
            <Title>Set A Budget, Start Saving Money</Title>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua ad
              minim veniam.
            </span>
          </RowItem>
        </Row>
      </Space>
    </Wrapper>
  );
}
