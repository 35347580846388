import React from "react";
import styled from "styled-components";
import { getConfig } from "../utils/configs";
import { Row, RowItem, Space, SuperTitle, Title } from "./StyledComponents";

const TitleWrapper = styled.div`
  text-align: center;
`;

const ProductImage = styled.img`
  width: 100%;
  /* border: solid 1px #b4b4b4; */
  box-shadow: 0 0 20px #939393;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;

export function Products() {
  return (
    <Space padding={100}>
      <TitleWrapper>
        <SuperTitle>Productos</SuperTitle>
        <Space padding={30} />
      </TitleWrapper>

      <Row>
        <RowItem>
          <Link href={getConfig("SONDEO_LANDING_URL")} target="_blank">
            <ProductImage src="/images/sondeo_product.jpg" />
          </Link>
        </RowItem>

        <RowItem>
          <Link href={getConfig("SONDEO_LANDING_URL")} target="_blank">
            <Title size={3}>Sondeo</Title>
            <span>
              Si tienes una idea, un proyecto o un negocio, te ayudamos a
              validar tus ideas con tus propios clientes antes de
              implementarlas. Ahorrando de esa manera tiempo y dinero en algo
              que tus clientes no necesitan. Dándoles la oportunidad de que sean
              parte de tu negocio y te ayuden a impulsarlo.
            </span>
          </Link>
        </RowItem>
      </Row>
      <Space padding={80} />
      <Row>
        <RowItem>
          <Link href={getConfig("MENU_LANDING_URL")} target="_blank">
            <ProductImage src="/images/menu_product.jpg" />
          </Link>
        </RowItem>
        <RowItem>
          <Link href={getConfig("MENU_LANDING_URL")} target="_blank">
            <Title size={3}>Menú</Title>
            <p>⏳ Próximamente</p>
            <p>
              Tus clientes podrán acceder a través de un código QR a tu Menú
              totalmente interactivo. Conteniendo categorías y subcategorías
              infinitas para filtrar diferente tipos de alimentos. Además
              tendrás la oportunidad de aparecer en nuestro buscador cuando los
              usuarios filtren las categrías que coincidan con tus productos
            </p>
          </Link>
        </RowItem>
      </Row>
    </Space>
  );
}
