import { lighten } from "polished";
import React from "react";
import styled from "styled-components";
import {
  Row,
  RowItem,
  Space,
  SubTitle,
  SuperTitle,
  Title,
} from "./StyledComponents";

const Wrapper = styled.div`
  text-align: center;
`;

const RowItemWrapper = styled.div`
  box-shadow: 0 0 30px ${(props) => lighten(0.4, props.theme.darkColor)};
  text-align: center;
`;

const Img = styled.div`
  width: 100%;
  height: 400px;
`;

const Img1 = styled(Img)`
  background-image: url("/images/cover_board1.jpg");
  background-size: cover;
`;

const Img2 = styled(Img)`
  background-image: url("/images/cover_board2.jpg");
  background-size: cover;
`;

export function Team() {
  return (
    <Wrapper>
      <Space padding={100}>
        <SuperTitle>Conocé al equipo</SuperTitle>
        <Space padding={30} />
        <Row>
          <RowItem></RowItem>
          <RowItem size={2}>
            <RowItemWrapper>
              <Img1 />
              <Space padding={20}>
                <Title size={1.2}>Tomás Arguelles</Title>
                <SubTitle size={0.9} color="grey">
                  Co-Founder
                </SubTitle>
              </Space>
            </RowItemWrapper>
          </RowItem>

          <RowItem size={2}>
            <RowItemWrapper>
              <Img2 />
              <Space padding={20}>
                <Title size={1.2}>Leosbel Poll</Title>
                <SubTitle size={0.9} color="grey">
                  Co-Founder
                </SubTitle>
              </Space>
            </RowItemWrapper>
          </RowItem>
          <RowItem></RowItem>
        </Row>
      </Space>
    </Wrapper>
  );
}
