export const getConfig = (key) => {
  return process.env[`REACT_APP_${key.toUpperCase()}`];
};

export const getListFromConfig = (key, separator = ";") => {
  const config = getConfig(key);
  if (config) {
    return config.split(separator);
  }
  return [];
};
