import { darken, transparentize } from "polished";
import React from "react";
import styled from "styled-components";
import { Navbar } from "./Navbar";

const Wrapper = styled.section`
  /* background: ${(props) => props.theme.primaryColor}; */
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.primaryColor} 0%,
    ${(props) => darken(0.2, props.theme.primaryColor)}
  );
  height: 100vh;

  .images {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 2;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .image {
    width: 30%;
    box-shadow: 0 0 70px
      ${(props) => transparentize(0.5, props.theme.darkColor)};
  }

  .image1 {
    transform: translate(200, 200);
  }

  .image3 {
    transform: translate(200, -200);
  }

  .image2 {
    transform: scale(1.4);
  }

  @keyframes first-clouds {
    0% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes second-clouds {
    0% {
      transform: scale(1.8);
    }
    50% {
      transform: scale(2);
    }
    100% {
      transform: scale(1.8);
    }
  }

  .cloud {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .second-clouds-layer {
    animation: second-clouds 5s linear infinite;
    animation-delay: -3s;
    bottom: 20px;
    filter: brightness(0) invert(1);
    opacity: 0.5;
  }

  .first-clouds-layer {
    filter: brightness(0) invert(1);
    animation: first-clouds 5s linear infinite;
  }

  .rocket {
    position: absolute;
    width: 100px;
    bottom: 330px;
    left: calc(50% - 50px);

    @media (max-width: 600px) {
      width: 100px;
      bottom: 200px;
      left: calc(50% - 50px);
    }
  }

  @keyframes rocket-fire {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .rocket-fire {
    position: absolute;
    width: 50px;
    bottom: 250px;
    left: calc(50% - 25px);
    animation: rocket-fire 0.3s linear infinite;

    @media (max-width: 600px) {
      width: 50px;
      bottom: 120px;
      left: calc(50% - 25px);
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .rocket {
      bottom: 200px;
      width: 70px;
      left: calc(50% - 35px);
    }

    .rocket-fire {
      bottom: 135px;
      left: calc(50% - 20px);
      width: 40px;
    }

    .cloud {
      position: absolute;
      bottom: -40px;
      left: 0;
      width: 100%;
    }
  }

  .light {
    z-index: 1;
    position: absolute;
    bottom: -50%;
    right: -50%;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      ${(props) => transparentize(0.8, props.theme.lightColor)}
    );

    @media (max-width: 600px) {
      width: 400%;
      background: linear-gradient(
        90deg,
        transparent 0%,
        ${(props) => transparentize(0.9, props.theme.lightColor)}
      );
    }
  }
`;

const Title = styled.h1`
  font-size: 3em;
  padding: 20px 40px;
  padding-bottom: 0;
  margin-bottom: 30px;
  text-align: center;
  color: ${(props) => props.theme.lightColor};

  @media (max-width: 600px) {
    font-size: 2em;
    padding: 30px;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 2.5em;
    padding-top: 0;
    margin-top: -40px;
  }
`;

const SubTitle = styled.p`
  font-size: 1.2em;
  padding: 20px;
  padding-top: 0;
  text-align: center;
  color: ${(props) => props.theme.lightColor};
`;

export function Cover() {
  return (
    <Wrapper>
      <Navbar />
      <Title>Hacé fácil tu negocio y tu vida</Title>
      <SubTitle>
        Relajáte y dejános ayudarte a crecer tu negocio mientras vivís mejor tu
        vida.
      </SubTitle>

      <img
        src="/images/rocket_fire.png"
        alt="Rocket fire"
        className="rocket-fire"
      />
      <img src="/images/rocket.png" alt="Rocket" className="rocket" />
      <img
        src="/images/blue_clouds.png"
        alt="Second clouds layer"
        className="cloud second-clouds-layer"
      />
      <img
        src="/images/blue_clouds.png"
        alt="First clouds layer"
        className="cloud first-clouds-layer"
      />

      <div className="light"></div>
    </Wrapper>
  );
}
